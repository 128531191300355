import React, { PureComponent } from 'react';
import { navigate } from 'gatsby'

import Layout from '../containers/Layout';

class Home extends PureComponent {
  componentDidMount() {
    navigate(`/index${Math.random() < 0.5 ? 1 : 2}`, { replace: true });
  }

  render() {
    return <Layout />;
  }
}

export default Home
